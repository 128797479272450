/* General Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
    height: 100%;
  }

  html {
    height: 100%;
  }
  
  h1 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
  }

  .header {
    width: 100%;
    background-color: #f5f5f5;
    color: black;
    padding: 20px;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    flex-shrink: 0;
  }
  
  .page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
  }

.main-content {
    flex: 1;
}

  .container {
    max-width: 600px;
    margin: 0 auto;
 /*   padding: 30px;*/
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .footer {
    width: 100%;
    background-color: #f5f5f5;
    color: black;
    text-align: center;
    margin-top: auto;
  }

  .twitter-icon {
    color: black;
    font-size: 1.5rem;
    margin-top: 10px;
    text-decoration: none;
    transition: color 0.3s ease;
  }


  .highlight-button {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    background-color: #f80021;
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .highlight-button:hover {
    background-color: #f0a800;
  }
  
  /* Tooltip container */
  .highlight-button .tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    display: none; /* Initially hidden */
    background-color: rgba(148, 145, 145, 0.9); /* Darker background */
    padding: 10px;
    border-radius: 5px;
    color: white;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
    width: 200px; /* Set the width for the tooltip */
  }

  .tooltip-text {
    color: black;
    font-weight: bold;
  }
  
  
  /* Image inside the tooltip */
  .highlight-button .tooltip .tooltip-image {
    width: 100%; /* Adjust the image size */
    max-height: 100px;
    object-fit: cover;
    margin-bottom: 8px; /* Space between image and text */
  }
  
  /* Show the tooltip on hover */
  .highlight-button:hover .tooltip {
    display: block; /* Show tooltip when hovered */
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .button-container button.disabled {
    background-color: #d3d3d3;
    color: #808081;
    cursor: not-allowed;
    border: 1px solid #ccc;
  }

  button {
    font-family: "Tahoma", sans-serif;
    font-size: 14px;
    color: black;
    background-color: #d4d0c8;
    border: 2px solid #fff;
    border-top-color: #808080; /* Darker top border */
    border-left-color: #808080; /* Darker left border */
    border-right-color: #fff; /* Lighter right border */
    border-bottom-color: #fff; /* Lighter bottom border */
    padding: 5px 10px;
    box-shadow: inset -1px -1px 0 #808080, inset 1px 1px 0 #fff; /* Inner shadow */
    text-shadow: 0px 1px 0px #fff; /* Subtle text emboss */
    cursor: pointer;
    outline: none;
  }
  /*
  button:hover {
    background-color: #3498db;
  }*/

  button.active {
    border-top-color: #fff; /* Reverse border colors on active */
    border-left-color: #fff;
    border-right-color: #808080;
    border-bottom-color: #808080;
    box-shadow: inset 1px 1px 0 #808080, inset -1px -1px 0 #fff; /* Invert inner shadow */
    background-color: #c0c0c0;
  }
  
  button:focus {
    outline: none;
  }
  
  input {
    width: 100%;
    max-width: 500px;
    padding: 12px;
    font-size: 1.1rem;
    margin-top: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #fafafa;
    transition: border-color 0.3s ease;
    display: block;
  }
  
  input:focus {
    border-color: #3498db;
    outline: none;
  }

  .output-container {
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
    font-size: 1.1rem;
    color: black;
  }
  
  p {
    max-width: 500px;
    margin: 20px auto;
    font-size: 1rem;
    color: #555;
    text-align: center;
  }
  
  /* Mode Description Styling */
  .mode-description {
    font-size: 1rem;
    margin-top: 15px;
    text-align: center;
    max-width: 500px;
    color: #777;
  }
  
  .retry {
    font-size: 1.2rem;
    color: #e74c3c;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
  
  /* Output Styling */
  .output {
    margin-top: 20px;
    text-align: center;
    font-size: 1.1rem;
    color: #2ecc71;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  
    input {
      font-size: 1rem;
      padding: 10px;
    }
  
    h1 {
      font-size: 1.5rem;
    }
  }
  